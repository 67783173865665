import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {useEffect} from "react";
import {AlertTitle} from "@mui/material";

// const Alert = (function Alert(props, ref) {
//     return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

export default function ErrorSnackbar({message, show}) {
    const [open, setOpen] = React.useState(show);

    useEffect(() => {
        setOpen(show)
    }, [show])


    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    return (
        <Stack spacing={2} sx={{ width: '100%' }}>
            {/*<Button variant="outlined" onClick={handleClick}>*/}
            {/*    Open success snackbar*/}
            {/*</Button>*/}
            <Snackbar open={open} autoHideDuration={7000} onClose={handleClose} anchorOrigin={{vertical: 'bottom',
                horizontal: 'center'}}>
                <MuiAlert onClose={handleClose} severity="error" sx={{ width: '100%', textAlign: "left" }}>
                    <AlertTitle>Error</AlertTitle>
                    {message ? message : 'The server is currently unable to handle the request due to excessive traffic or maintenance. Please try again in a moment'}
                </MuiAlert>
            </Snackbar>
        </Stack>
    );
}
