import JobTitlePrompt from "./components/JobTitlePrompt";
import {ThemeProvider} from "@emotion/react";
import {theme} from "./theme";
import logo from "./static/skillpaca_logo_full_lockup.svg"
import {Typography} from "@mui/material";
import React from "react";

function App() {
  return (
      <ThemeProvider theme={theme}>
          <div style={{textAlign: "center", paddingTop: 24}}>
              <img width={300} src={logo}/>
              <Typography variant={"h4"}>Generate a high quality skills progression template within minutes</Typography>
              <Typography variant={"p"}>Choose a role, generate skills and receive a full skills progression framework draft that is tailored to your organisation</Typography>
          </div>
        <JobTitlePrompt/>
      </ThemeProvider>
  );
}

export default App;
