import {Box, Button, IconButton, Modal, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import EditIcon from '@mui/icons-material/Edit';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',
    // border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

export default function EditDescriptionModal({description, title, index, updateSkillTitle, updateSkillDescription}) {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [skillTitle, setSkillTitle] = useState(title)
    const [skillDescription, setSkillDescription] = useState(description)

    const handleSubmit = () => {
        updateSkillTitle(skillTitle, index)
        updateSkillDescription(skillDescription, index)
        handleClose()
    }

    return (
        <div>
            <IconButton color={"primary"} aria-label="edit" onClick={handleOpen}>
                <EditIcon />
            </IconButton>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        {title}
                    </Typography>
                    <TextField
                        id={"skill-title-" + title}
                        label="Title"
                        className="outlined-multiline-static"
                        style={{width: '100%'}}
                        // className={classes.textField}
                        value={skillTitle}
                        onChange={(e) => setSkillTitle(e.target.value)}
                        margin="normal"
                    />
                    <TextField
                        id={"skill-description-" + title}
                        label="Description"
                        className="outlined-multiline-static"
                        multiline={true}
                        rows={8}
                        style={{width: '100%'}}
                        // className={classes.textField}
                        value={skillDescription}
                        onChange={(e) => setSkillDescription(e.target.value)}
                        margin="normal"
                    />
                    <Button onClick={() => handleClose()}>Cancel</Button>
                    <Button variant={"contained"} style={{float: "right"}} onClick={() => handleSubmit()}>Submit</Button>
                </Box>
            </Modal>
        </div>
    );
}
