import React, {useState} from "react";
import {saveAs} from "file-saver";
import Excel from "exceljs";
import {LoadingButton} from "@mui/lab";

const ExcelDownloadButton = ({ jobTitle, skills, skillsData }) => {
    const [isLoading, setLoading] = useState(false);

    const handleClick = async () => {
        setLoading(true);
        try {
            const workbook = new Excel.Workbook();
            const worksheet = workbook.addWorksheet("My Sheet");
            worksheet.columns = [
                { header: "Skill", key: "skill", width: 30 },
                { header: "Description", key: "description", width: 40 },
                { header: "Level 1", key: "level1", width: 40 },
                { header: "Level 2", key: "level2", width: 40 },
                { header: "Level 3", key: "level3", width: 40 },
                { header: "Level 4", key: "level4", width: 40 },
                { header: "Level 5", key: "level5", width: 40 },
            ];

            worksheet.addRows(skills.map((skill, index) => {
                return {
                    skill: skill,
                    description: skillsData[index] && skillsData[index].description ? skillsData[index].description : '',
                    level1: skillsData[index] && skillsData[index].rubric && skillsData[index].rubric[0] ? skillsData[index].rubric[0].map(item => `- ${item}\n`).join('') : '',
                    level2: skillsData[index] && skillsData[index].rubric && skillsData[index].rubric[1] ? skillsData[index].rubric[1].map(item => `- ${item}\n`).join('') : '',
                    level3: skillsData[index] && skillsData[index].rubric && skillsData[index].rubric[2] ? skillsData[index].rubric[2].map(item => `- ${item}\n`).join('') : '',
                    level4: skillsData[index] && skillsData[index].rubric && skillsData[index].rubric[3] ? skillsData[index].rubric[3].map(item => `- ${item}\n`).join('') : '',
                    level5: skillsData[index] && skillsData[index].rubric && skillsData[index].rubric[4] ? skillsData[index].rubric[4].map(item => `- ${item}\n`).join('') : '',
                }
            }));
            worksheet.eachRow((row, rowNumber) => {
                row.eachCell((cell, colNumber) => {
                    cell.alignment = {
                        wrapText: true,
                        vertical: "top"
                    }
                })
            });
            const excelBuffer = await workbook.xlsx.writeBuffer();
            saveAs(new Blob([excelBuffer]), jobTitle + "-skills-matrix.xlsx");
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <LoadingButton loading={isLoading} color={"primary"} variant={"contained"} onClick={handleClick} disabled={isLoading}>
            Download your matrix
        </LoadingButton>
    );
};

export default ExcelDownloadButton;
