import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_BASE_URL

export async function fetchSkillsList(jobTitle, specifics) {
    const response = await axios.post(`${BASE_URL}/skills/list`, {
        jobTitle: jobTitle,
        specifics: specifics ? specifics : ''
    })
    return response.data
}

export async function fetchSimilarSkills(skill) {
    const response = await axios.post(`${BASE_URL}/skills/similar`, {
        skill: skill,
    })
    return response.data
}

export async function fetchSkillDescription(jobTitle, skill) {
    const response = await axios.post(`${BASE_URL}/skills/description`, {
        jobTitle: jobTitle,
        skill: skill
    })
    return response.data
}

export async function fetchSkillRubric(skill, description) {
    const response = await axios.post(`${BASE_URL}/skills/rubric`, {
        skill: skill,
        description: description ? description : ''
    })
    return response.data
}

