import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    Checkbox,
    Grid,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Typography
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import {Skeleton} from "@mui/lab";
import ExcelDownloadButton from "./ExcelDownloadButton";
import EditDescriptionModal from "./EditDescriptionModal";
import {fetchSimilarSkills, fetchSkillDescription, fetchSkillRubric, fetchSkillsList} from "../service/ApiService";
import Rubric from "./Rubric";
import ErrorSnackbar from "./ErrorSnackbar";

function JobTitlePrompt() {
    const [jobTitle, setJobTitle] = useState('');
    const [jobResponsibilities, setJobResponsibilities] = useState('');
    const [addSkill, setAddSkill] = useState('');
    const [isGenerating, setIsGenerating] = useState(false)

    const [generatedSkills, setGeneratedSkills] = useState([]);
    const [generatedSkillsIndentation, setGeneratedSkillsIndentation] = useState({})
    const [generatedSkillsIsGeneratingSimilar, setGeneratedSkillsIsGeneratingSimilar] = useState({})

    const [selectedSkills, setSelectedSkills] = useState([])
    const [checked, setChecked] = useState({});

    const [skillsData, setSkillsData] = useState({})
    const [isGeneratingRubric, setIsGeneratingRubric] = useState({})

    const [isError, setIsError] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')

    useEffect(() => {
        const newSelectedSkills = []
        Object.keys(checked).forEach(index => {
            if (checked[index]) {
                newSelectedSkills.push(generatedSkills[index])
            }
        })
        setSelectedSkills(newSelectedSkills)
    }, [checked])

    const handleToggle = (index) => () => {
        const newChecked = {...checked};
        newChecked[index] = !newChecked[index]
        setChecked(newChecked);
    };

    const handleFetchSkills = async () => {
        setIsGenerating(true)
        setIsError(false)
        try {
            const {skills} = await fetchSkillsList(jobTitle, jobResponsibilities)
            setGeneratedSkills(skills)
        } catch (e) {
            console.log(e)
            setIsError(true)
            setGeneratedSkills([])
        }
        setIsGenerating(false)
    };

    const handleAddSimilarSkills = async (skill, index) => {
        setGeneratedSkillsIsGeneratingSimilar({...generatedSkillsIsGeneratingSimilar, [index]: true})
        setIsError(false)

        try {
            let moreSkills = []
            const response = await fetchSimilarSkills(skill)
            moreSkills = response.skills

            const indexAfter = index + 1
            let newArray = [...generatedSkills.slice(0, indexAfter), ...moreSkills, ...generatedSkills.slice(indexAfter)];

            const newChecked = {...checked};
            const checkedKeys = Object.keys(newChecked)

            for (let i = checkedKeys.length; i > 0; i--) {
                if (checkedKeys[i] >= indexAfter && newChecked[checkedKeys[i]]) {
                    newChecked[parseInt(checkedKeys[i]) + moreSkills.length] = true
                    newChecked[checkedKeys[i]] = false
                }
            }

            for (let i = 0; i < moreSkills.length; i++) {
                setGeneratedSkillsIndentation((generatedSkillsIndentation) => ({
                    ...generatedSkillsIndentation,
                    [indexAfter + i]: generatedSkillsIndentation[index] ? generatedSkillsIndentation[index] + 1 : 1
                }))
            }

            setGeneratedSkills(newArray);
            setChecked(newChecked)
            setGeneratedSkillsIsGeneratingSimilar({...generatedSkillsIsGeneratingSimilar, [index]: false})

        } catch (e) {
            setGeneratedSkillsIsGeneratingSimilar({...generatedSkillsIsGeneratingSimilar, [index]: false})
            setIsError(true)
        }
    }

    const handleAddNewSkill = async () => {
        setAddSkill('')
        setGeneratedSkills(generatedSkills.concat(addSkill));
        setSelectedSkills(selectedSkills.concat(addSkill))
        setChecked({...checked, [generatedSkills.length]: true})
    }

    const generateSkillDescription = async (skill) => {
        const response = await fetchSkillDescription(jobTitle, skill)
        return response.description
    }

    const handleGenerateRubric = async (skill, index) => {

        setIsError(false)
        setIsGeneratingRubric((isGeneratingRubric) => ({...isGeneratingRubric, [index]: true}))

        let existingDescription = ''
        if (skillsData[index] && skillsData[index].description) {
            existingDescription = skillsData[index].description
        }
        try {
            if (!skillsData[index] || !skillsData[index].rubric) {
                const [response, description] = await Promise.all([fetchSkillRubric(skill, existingDescription), generateSkillDescription(skill)])
                const rubric = response.rubric
                const newSkillsData = {...skillsData}

                if (newSkillsData[index]) {
                    newSkillsData[index] = {...newSkillsData[index], rubric: rubric, description: description}
                } else {
                    newSkillsData[index] = {rubric: rubric, description: description}
                }
                setSkillsData((skillsData) => ({...skillsData, ...newSkillsData}))
            } else {
                const response = await fetchSkillRubric(skill, existingDescription)
                const rubric = response.rubric
                const newSkillsData = {...skillsData}

                if (newSkillsData[index]) {
                    newSkillsData[index] = {...newSkillsData[index], rubric: rubric}
                } else {
                    newSkillsData[index] = {rubric: rubric}
                }
                setSkillsData((skillsData) => ({...skillsData, ...newSkillsData}))
            }
        }catch (e) {
            setIsError(true)
        }


        setIsGeneratingRubric((isGeneratingRubric) => ({...isGeneratingRubric, [index]: false}))
    }

    const updateSkillDescription = (description, index) => {
        const newSkillsData = {...skillsData}

        if (newSkillsData[index]) {
            newSkillsData[index] = {...newSkillsData[index], description: description}
        } else {
            newSkillsData[index] = {description: description}
        }
        setSkillsData(newSkillsData)
    }

    const updateSkillTitle = (title, index) => {
        const selectSkillsNew = [...selectedSkills]
        selectSkillsNew[index] = title
        setSelectedSkills(selectSkillsNew)
    }

    const updateRubric =  (newRubric, skillIndex) => {
        const newSkillsData = JSON.parse(JSON.stringify(skillsData))

        if (newSkillsData[skillIndex] && newSkillsData[skillIndex].rubric) {
            newSkillsData[skillIndex].rubric = newRubric
        }
        setSkillsData(newSkillsData)
    }

    return (
        <div style={{paddingTop: 24}}>
            <ErrorSnackbar show={isError}/>
            {/*<ProgressBar activeStep={0}/>*/}
            <form noValidate autoComplete="off" style={{textAlign: "center", marginBottom: 16}}>
                <div>
                    <TextField
                        id="job-title"
                        label="Job Title"
                        // className={classes.textField}
                        value={jobTitle}
                        onChange={(e) => setJobTitle(e.target.value)}
                        margin="normal"
                    />
                </div>
                <div>
                    <TextField
                        id="job-responsibilities"
                        label="Add some context to the role (Optional)"
                        helperText="Enter in a few key words or phrases about the role to help focus your results"
                        placeholder="SEO focus, customer facing, uses Salesforce, etc."
                        className="outlined-multiline-static"
                        multiline={true}
                        rows={4}
                        // className={classes.textField}
                        value={jobResponsibilities}
                        onChange={(e) => setJobResponsibilities(e.target.value)}
                        margin="normal"
                    />
                </div>
                <div>
                    <LoadingButton loading={isGenerating} variant="contained" color="primary" onClick={handleFetchSkills}>
                        Generate Skills
                    </LoadingButton>
                </div>
            </form>
            {(generatedSkills && generatedSkills.length) ? (
                <React.Fragment>
                    <Grid container spacing={2} style={{minHeight: 600}}>
                        <Grid item xs={6}>
                            <List sx={{width: '100%'}}>
                                <ListItem>
                                    <ListItemText primary={
                                        <Typography variant={"h5"}>Generated skills</Typography>}/>
                                </ListItem>
                                {generatedSkills && generatedSkills.map((skill, index) => {
                                    const labelId = `checkbox-list-label-${index}`;

                                    return (skill &&
                                        <ListItem key={skill + "-" + index}
                                                  secondaryAction={
                                                      <LoadingButton loading={generatedSkillsIsGeneratingSimilar[index]}
                                                                     onClick={() => handleAddSimilarSkills(skill, index)}>Generate
                                                          similar skills</LoadingButton>
                                                  }
                                        >
                                            <ListItemButton role={undefined} onClick={handleToggle(index)} dense
                                                            style={{width: "75%"}}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={checked[index] === true}
                                                        tabIndex={-1}
                                                        disableRipple
                                                        inputProps={{'aria-labelledby': labelId}}
                                                    />
                                                </ListItemIcon>
                                                <ListItemText id={labelId} primary={skill} style={{
                                                    maxWidth: '75%',
                                                    paddingLeft: generatedSkillsIndentation[index] ? generatedSkillsIndentation[index] * 32 : 0
                                                }}/>
                                            </ListItemButton>
                                        </ListItem>
                                    )
                                })}
                                <ListItem key={"add-skill"} secondaryAction={
                                    <Button variant="contained" color="primary" onClick={handleAddNewSkill}>
                                        Add Skill
                                    </Button>
                                }>
                                    <TextField
                                        id="add-skill"
                                        label="Add a skill"
                                        style={{width: "75%"}}
                                        // className={classes.textField}
                                        value={addSkill}
                                        onChange={(e) => setAddSkill(e.target.value)}
                                        margin="normal"
                                    />
                                </ListItem>
                            </List>
                        </Grid>
                        <Grid item xs={6}>
                            <div style={{position: "sticky", top: 0}}>
                                <List sx={{width: '100%'}}>
                                    <ListItem>
                                        <ListItemText primary={
                                            <Typography variant={"h5"}>Your selected skills ({selectedSkills.length})</Typography>}/>
                                    </ListItem>
                                    {selectedSkills && selectedSkills.map((skill, index) => {
                                        return (
                                            <ListItem key={skill + "-" + index}>
                                                <ListItemText primary={skill}/>
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={0} style={{paddingLeft: 16, paddingRight: 16}}>
                        <Grid item xs={12} style={{textAlign: "center", paddingTop: 32, paddingBottom: 32}}>
                            <Typography variant={"h3"}>Your skills matrix</Typography>
                        </Grid>
                        <Grid item xs={2} style={{borderRight: '1px solid #D3D3D3'}}></Grid>
                        <Grid item xs={2} style={{
                            borderTop: '1px solid #D3D3D3',
                            borderRight: '1px solid #D3D3D3',
                            padding: 8
                        }}>
                            <Typography><strong>Level 1</strong></Typography>
                        </Grid>
                        <Grid item xs={2} style={{
                            borderTop: '1px solid #D3D3D3',
                            borderRight: '1px solid #D3D3D3',
                            padding: 8
                        }}>
                            <Typography><strong>Level 2</strong></Typography>
                        </Grid>
                        <Grid item xs={2} style={{
                            borderTop: '1px solid #D3D3D3',
                            borderRight: '1px solid #D3D3D3',
                            padding: 8
                        }}>
                            <Typography><strong>Level 3</strong></Typography>
                        </Grid>
                        <Grid item xs={2} style={{
                            borderTop: '1px solid #D3D3D3',
                            borderRight: '1px solid #D3D3D3',
                            padding: 8
                        }}>
                            <Typography><strong>Level 4</strong></Typography>
                        </Grid>
                        <Grid item xs={2} style={{
                            borderTop: '1px solid #D3D3D3',
                            borderRight: '1px solid #D3D3D3',
                            padding: 8
                        }}>
                            <Typography><strong>Level 5</strong></Typography>
                        </Grid>
                        <Grid item xs={12}>
                            {selectedSkills.map((skill, index) => {
                                return (
                                    <React.Fragment key={skill + "-" + index}>
                                        <Grid container spacing={0}>
                                            <Grid item xs={2} style={{
                                                borderTop: '1px solid #D3D3D3',
                                                borderRight: '1px solid #D3D3D3',
                                                borderLeft: '1px solid #D3D3D3',
                                                padding: 8
                                            }}>
                                                <span>
                                                    <div style={{paddingBottom: 8}}>
                                                        {skillsData && skillsData[index] && skillsData[index].description && (
                                                            <div style={{float: "right"}}>
                                                                <EditDescriptionModal
                                                                    title={skill}
                                                                    description={skillsData[index].description}
                                                                    index={index}
                                                                    updateSkillDescription={updateSkillDescription}
                                                                    updateSkillTitle={updateSkillTitle}/>
                                                            </div>
                                                        )}
                                                        <div><strong>{skill}</strong></div>
                                                    </div>
                                                    {skillsData && skillsData[index] && skillsData[index].description && (
                                                        <div>
                                                            <Typography>
                                                                {skillsData[index].description}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                    <LoadingButton loading={isGeneratingRubric[index]} variant={"outlined"} color={"primary"} onClick={() => handleGenerateRubric(skill, index)}>
                                                        {skillsData[index] && skillsData[index].rubric ? 'Regenerate Rubric' : 'Generate Rubric'}
                                                    </LoadingButton>
                                                </span>
                                            </Grid>
                                            {!isGeneratingRubric[index] && skillsData && skillsData[index] && skillsData[index].rubric && Array.isArray(skillsData[index].rubric) ?
                                                <Rubric fullRubric={skillsData[index].rubric} skillIndex={index} updateRubric={updateRubric}/>
                                                :
                                                <React.Fragment>
                                                    <EmptyGrid isLoading={isGeneratingRubric[index]}/>
                                                    <EmptyGrid isLoading={isGeneratingRubric[index]}/>
                                                    <EmptyGrid isLoading={isGeneratingRubric[index]}/>
                                                    <EmptyGrid isLoading={isGeneratingRubric[index]}/>
                                                    <EmptyGrid isLoading={isGeneratingRubric[index]}/>
                                                </React.Fragment>
                                            }
                                        </Grid>
                                    </React.Fragment>
                                )
                            })}
                        </Grid>
                        <Grid item xs={12} style={{borderTop: "1px solid #D3D3D3"}}/>
                    </Grid>
                    <Grid container>
                        <Grid item xs={12} style={{textAlign: "center", margin: 24}}>
                            <ExcelDownloadButton jobTitle={jobTitle} skills={selectedSkills} skillsData={skillsData}/>
                        </Grid>
                    </Grid>
                </React.Fragment>
            ) : ''}
        </div>
    );
}

function ProgressBar({activeStep}) {
    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
                <Step key={'Define your skills'}>
                    <StepLabel>Define your skills</StepLabel>
                </Step>
                <Step key={'Add descriptions to you skills'}>
                    <StepLabel>Add descriptions to you skills</StepLabel>
                </Step>
                <Step key={'Create your rubric'}>
                    <StepLabel>Create your rubric</StepLabel>
                </Step>
                <Step key={'Share your new skills matrix'}>
                    <StepLabel>Share your new skills matrix</StepLabel>
                </Step>
            </Stepper>
        </Box>
    )
}

function EmptyGrid({isLoading}) {
    if (isLoading) {
        return (
            <Grid item xs={2} style={{borderTop: '1px solid #D3D3D3', borderRight: '1px solid #D3D3D3'}}>
                <div style={{width: "80%", margin: "auto"}}>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}} width={"100%"} style={{visibility: "hidden"}}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}}
                              width={Math.floor(Math.random() * (100 - 70 + 1) + 70) + "%"}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}}
                              width={Math.floor(Math.random() * (100 - 70 + 1) + 70) + "%"}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}}
                              width={Math.floor(Math.random() * (100 - 70 + 1) + 70) + "%"}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}} width={"100%"} style={{visibility: "hidden"}}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}}
                              width={Math.floor(Math.random() * (100 - 70 + 1) + 70) + "%"}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}}
                              width={Math.floor(Math.random() * (100 - 70 + 1) + 70) + "%"}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}}
                              width={Math.floor(Math.random() * (100 - 70 + 1) + 70) + "%"}/>
                    <Skeleton variant="text" sx={{fontSize: '1rem'}} width={"100%"} style={{visibility: "hidden"}}/>
                </div>
            </Grid>
        )
    } else {
        return <Grid item xs={2} style={{borderTop: '1px solid #D3D3D3', borderRight: '1px solid #D3D3D3'}}/>
    }
}

export default JobTitlePrompt;
