import {Grid, IconButton, TextField, Typography} from "@mui/material";
import React, {useState} from "react";
import DoneIcon from '@mui/icons-material/Done';
import DeleteIcon from '@mui/icons-material/Delete';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';

export default function Rubric({fullRubric, updateRubric, skillIndex}) {

    const [rubric, setRubric] = useState(fullRubric)

    function onDragEnd(result) {
        const newRubric = JSON.parse(JSON.stringify(rubric))
        let removed = newRubric[result.source.droppableId].splice(result.source.index, 1)
        newRubric[result.destination.droppableId].splice(result.destination.index, 0, removed[0]);
        setRubric(newRubric)
        updateRubric(newRubric, skillIndex)
    }

    const updateRubricLevelPoint =  (updatedPoint, levelIndex, pointIndex) => {
        const newRubric = JSON.parse(JSON.stringify(rubric))
        if (newRubric && newRubric[levelIndex] && newRubric[levelIndex][pointIndex]) {
            newRubric[levelIndex][pointIndex] = updatedPoint
        }
        setRubric(newRubric)
        updateRubric(newRubric, skillIndex)
    }

    const deleteRubricLevelPoint =  (levelIndex, pointIndex) => {
        const newRubric = JSON.parse(JSON.stringify(rubric))
        if (newRubric && newRubric[levelIndex] && newRubric[levelIndex][pointIndex]) {
            newRubric[levelIndex].splice(pointIndex, 1)
        }
        setRubric(newRubric)
        updateRubric(newRubric, skillIndex)
    }


    return (
        <RubricLevels
            rubric={rubric}
            onDragEnd={onDragEnd}
            updateRubricLevelPoint={updateRubricLevelPoint}
            deleteRubricLevelPoint={deleteRubricLevelPoint}
        />
    );
}

export function RubricLevels({rubric, onDragEnd, updateRubricLevelPoint, deleteRubricLevelPoint}) {

    return (
        <DragDropContext
            onDragEnd={onDragEnd}
        >
            {/*<Grid container columns={5} spacing={2} style={{minHeight: 450, overflow:"scroll"}}>*/}
                {rubric.map((level, index) => {
                    return (
                        <RubricLevel key={index} level={level} levelIndex={index} updateRubricLevelPoint={updateRubricLevelPoint} deleteRubricLevelPoint={deleteRubricLevelPoint}/>
                    )
                })}
            {/*</Grid>*/}
        </DragDropContext>
    )
}

function RubricLevel({level, levelIndex, updateRubricLevelPoint, deleteRubricLevelPoint}) {
    return (
        <React.Fragment>
            <Droppable droppableId={levelIndex + ''}>
                {provided =>
                    <Grid item xs={2} ref={provided.innerRef}
                         {...provided.droppableProps}
                         style={{
                             borderTop: '1px solid #D3D3D3',
                             borderRight: '1px solid #D3D3D3',
                             padding: 8
                         }}
                         key={levelIndex + ''}
                    >
                        {level.map((point, pointIndex) => {
                            return <LevelDotPoint key={levelIndex + '-' + pointIndex} point={point} levelIndex={levelIndex}
                                                  pointIndex={pointIndex}
                                                  updateRubricLevelPoint={updateRubricLevelPoint}
                                                  deleteRubricLevelPoint={deleteRubricLevelPoint}
                            />
                        })}
                        {provided.placeholder}
                    </Grid>
                }
            </Droppable>
        </React.Fragment>
    )
}

function LevelDotPoint({point, levelIndex, pointIndex, updateRubricLevelPoint, deleteRubricLevelPoint}) {

    const [isEditing, setIsEditing] = useState(false)

    const editPoint = (event) => {
        updateRubricLevelPoint(event.target.value, levelIndex, pointIndex)
    }

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            // Enter key was pressed
            setIsEditing(false)
        }
    };

    if (isEditing) {
        return (
            <div>
                <TextField
                    value={point}
                    // label="Description"
                    className="outlined-multiline-static"
                    multiline={true}
                    // rows={2}
                    minRows={3}
                    maxRows={10}
                    style={{width: '100%'}}
                    onChange={(e) => editPoint(e)}
                    onKeyDown={handleKeyDown}
                />
                <IconButton aria-label="accept" component="label" onClick={() => deleteRubricLevelPoint(levelIndex, pointIndex)}>
                    <DeleteIcon />
                </IconButton>
                <IconButton color="success"
                            aria-label="accept"
                            component="label"
                            style={{float: "right"}}
                            onClick={() => setIsEditing(false)}>
                    <DoneIcon />
                </IconButton>
            </div>
        )
    }

    return (
        <Draggable key={point} draggableId={point} index={pointIndex}>
            {provided => (
                <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    // elevation={2}
                    key={point}
                    style={{padding: 16, ...provided.draggableProps.style, position: 'static', background: "white"}}
                >
                    <Typography variant={"span"} onClick={() => setIsEditing(true)} sx={{
                        "&:hover": {
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        },
                    }}>{point}</Typography>
                </div>
            )}
        </Draggable>
    )
}
